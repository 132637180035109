export const PRIVACY_POLICY_DATA = [
    {
      title: '1. POSTANOWIENIA OGÓLNE',
      paragraphs: [
        '1. Administratorem danych osobowych zbieranych za pośrednictwem strony internetowej www.bodymedgroup.pl jest BodyMed Group s.c., adres siedziby: ul. Gdańska 79, adres do doręczeń: ul. Gdańska 79 , NIP: 947-198-63-99, adres poczty elektronicznej: biuro@bodymedgroup.pl, dalej „Administrator”, będący jednoczeście Usługodawcą., adres poczty elektronicznej (e-mail): biuro@bodymedgroup.pl, zwany/a dalej "Administratorem"',
        '2. Dane osobowe zbierane przez Administratora za pośrednictwem strony internetowej są przetwarzane zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), zwane dalej RODO oraz ustawą o ochronie danych osobowych z dnia 10 maja 2018 r.',
      ],
    },
    {
      title: '2. RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH, CEL I ZAKRES ZBIERANIA DANYCH',
      paragraphs: [
        '1. CEL PRZETWARZANIA I PODSTAWA PRAWNA. Administrator przetwarza dane osobowe za pośrednictwem strony   www.bodymedgroup.pl w przypadku: a. skorzystania przez użytkownika z formularza kontaktowego. Dane osobowe są przetwarzane na podstawie art. 6 ust. 1 lit. f) RODO jako prawnie usprawiedliwiony interes Administratora.',
        '2. RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH. Administrator przetwarza następujące kategorie danych osobowych użytkownika: a. Imię i nazwisko, b. Adres e-mail, c. Numer telefonu,',
        '3. OKRES ARCHIWIZACJI DANYCH OSOBOWYCH. Dane osobowe użytkowników przechowywane są przez Administratora a. w przypadku, gdy podstawą przetwarzania danych jest wykonanie umowy, tak długo, jak jest to niezbędne do wykonania umowy, a po tym czasie przez okres odpowiadający okresowi przedawnienia roszczeń. Jeżeli przepis szczególny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a dla roszczeń o świadczenia okresowe oraz roszczeń związanych z prowadzeniem działalności gospodarczej - trzy lata. b. w przypadku, gdy podstawą przetwarzania danych jest zgoda, tak długo, aż zgoda nie zostanie odwołana, a po odwołaniu zgody przez okres czasu odpowiadający okresowi przedawnienia roszczeń jakie może podnosić Administrator i jakie mogą być podnoszone wobec niego. Jeżeli przepis szczególny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a dla roszczeń o świadczenia okresowe oraz roszczeń związanych z prowadzeniem działalności gospodarczej - trzy lata.',
        '4. Podczas korzystania ze strony internetowej mogą być pobierane dodatkowe informacje, w szczególności: adres IP przypisany do komputera użytkownika lub zewnętrzny adres IP dostawcy Internetu, nazwa domeny, rodzaj przeglądarki, czas dostępu, typ systemu operacyjnego.',
        '5. Od użytkowników mogą być także gromadzone dane nawigacyjne, w tym informacje o linkach i odnośnikach, w które zdecydują się kliknąć lub innych czynnościach, podejmowanych na stronie internetowej. Podstawą prawną tego rodzaju czynności jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO), polegający na ułatwieniu korzystania z usług świadczonych drogą elektroniczną oraz na poprawie funkcjonalności tych usług.',
        '6. Podanie danych osobowych przez użytkownika jest dobrowolne.',
        '7. Dane osobowe będą przetwarzane także w sposób zautomatyzowany w formie profilowania, o ile użytkownik wyrazi na to zgodę na podstawie art. 6 ust. 1 lit. a) RODO. Konsekwencją profilowania będzie przypisanie danej osobie profilu w celu podejmowania dotyczących jej decyzji bądź analizy lub przewidywania jej preferencji, zachowań i postaw.',
        '8. Administrator dokłada szczególnej staranności w celu ochrony interesów osób, których dane dotyczą, a w szczególności zapewnia, że zbierane przez niego dane są: a. przetwarzane zgodnie z prawem, b. zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu przetwarzaniu niezgodnemu z tymi celami, c. merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane oraz przechowywane w postaci umożliwiającej identyfikację osób, których dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania.',
      ],
    },
    {
      title: '3. UDOSTĘPNIENIE DANYCH OSOBOWYCH',
      paragraphs: [
        '1. Dane osobowe użytkowników przekazywane są dostawcom usług, z których korzysta Administrator przy prowadzeniu strony internetowej. Dostawcy usług, którym przekazywane są dane osobowe, w zależności od uzgodnień umownych i okoliczności, albo podlegają poleceniom Administratora co do celów i sposobów przetwarzania tych danych (podmioty przetwarzające) albo samodzielnie określają cele i sposoby ich przetwarzania (administratorzy).',
        '2. Dane osobowe użytkowników są przechowywane wyłącznie na terenie Europejskiego Obszaru Gospodarczego (EOG).',
      ],
    },
    {
      title: '4. PRAWO KONTROLI, DOSTĘPU DO TREŚCI WŁASNYCH DANYCH ORAZ ICH POPRAWIANIA',
      paragraphs: [
        '1. Osoba, której dane dotyczą, ma prawo dostępu do treści swoich danych osobowych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.',
        '2. Podstawy prawne żądania użytkownika: a. Dostęp do danych – art. 15 RODO b. Sprostowanie danych – art. 16 RODO. c. Usunięcie danych (tzw. prawo do bycia zapomnianym) – art. 17 RODO. d. Ograniczenie przetwarzania – art. 18 RODO. e. Przeniesienie danych – art. 20 RODO. f. Sprzeciw – art. 21 RODO g. Cofnięcie zgody – art. 7 ust. 3 RODO.',
        '3. W celu realizacji uprawnień, o których mowa w pkt 2 można wysłać stosowną wiadomość e-mail na adres: biuro@bodymedgroup.pl.',
        '4. W sytuacji wystąpienia przez użytkownika z uprawnieniem wynikającym z powyższych praw, Administrator spełnia żądanie albo odmawia jego spełnienia niezwłocznie, nie później jednak niż w ciągu miesiąca po jego otrzymaniu. Jeżeli jednak - z uwagi na skomplikowany charakter żądania lub liczbę żądań – Administrator nie będzie mógł spełnić żądania w ciągu miesiąca, spełni je w ciągu kolejnych dwóch miesięcy informując użytkownika uprzednio w terminie miesiąca od otrzymania żądania - o zamierzonym przedłużeniu terminu oraz jego przyczynach.',
        '5. W przypadku stwierdzenia, że przetwarzanie danych osobowych narusza przepisy RODO, osoba, której dane dotyczą, ma prawo wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.',
      ],
    },
    {
      title: '5. POSTANOWIENIA KOŃCOWE',
      paragraphs: [
        'Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych osobowych odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną, przetwarzaniem z naruszeniem obowiązujących przepisów oraz zmianą, utratą, uszkodzeniem lub zniszczeniem. Administrator udostępnia odpowiednie środki techniczne zapobiegające pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione, danych osobowych przesyłanych drogą elektroniczną. W sprawach nieuregulowanych niniejszą Polityką prywatności stosuje się odpowiednio przepisy RODO oraz inne właściwe przepisy prawa polskiego.',
      ],
    },
  ]
  