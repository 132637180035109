import React from 'react';
import styled from 'styled-components'

import { Wrapper } from '../common/wrapper'


import { Heading } from '../common/heading'
import { device } from '../../utils/breakpoints'

import { PRIVACY_POLICY_DATA } from './data'



const WrapperPrivacyPolicy = styled(Wrapper)`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
 
`

const ContainerTextPrivacyPolicy = styled.div `
  padding: 4rem 0;
`

const HeadingPrivacyPolicy = styled(Heading)`
  margin-bottom: 3rem;
  font-size: ${({ theme }) => theme.fonts.size.m};
  color: ${({ theme }) => theme.colors.black};
`

const Article = styled.article`
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 10rem;
  }
`

const ArticleTitlePrivacyPolicy = styled(Heading)`
  margin-bottom: 2rem;
  
`

const ArticleParagraph = styled.p`
  margin-bottom: 1rem;
  font-size: ${({ theme }) => theme.fonts.size.xs};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.black};

  &:last-child {
    margin-bottom: 0;
  }
`




export const PrivacyPolicy = () => {

    return (
        <WrapperPrivacyPolicy>
        
       <ContainerTextPrivacyPolicy>
          <HeadingPrivacyPolicy as='h2' h2>
            Polityka Prywatności
          </HeadingPrivacyPolicy>
      
          {PRIVACY_POLICY_DATA.map(article => (
            <Article key={article.title}>
              <ArticleTitlePrivacyPolicy as='h3' h3>
                {article.title}
              </ArticleTitlePrivacyPolicy>
              {article.paragraphs.map((paragraph, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <ArticleParagraph key={i}>{paragraph}</ArticleParagraph>
              ))}
            </Article>
          ))}
    
        </ContainerTextPrivacyPolicy>
        </WrapperPrivacyPolicy>
    )
}