import React from "react"

import Layout from "../components/layout"
import { PrivacyPolicy } from '../components/privacyPolicy/index' 
import SEO from '../components/seo'

const BydgoszczPrivacyPolicyPage = () => (
  <Layout bydgoszcz>
    <SEO
      title='Bydgoszcz Polityka prywatnosci'
      description='Polityka prywatnosci'
      url='https://bodymedgroup.pl/bydgoszcz-polityka-prywatnosci'
    />

    <PrivacyPolicy/>
  </Layout>
)

export default BydgoszczPrivacyPolicyPage
